import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HTML5 Conference 2018 にメドレーが協賛しました！",
  "date": "2018-12-07T03:00:15.000Z",
  "slug": "entry/2018/12/07/120015",
  "tags": ["medley"],
  "hero": "./2018_12_07.png",
  "heroAlt": "html5 conf"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の日下です。昨年新卒として入社してからはや 1 年半、最近は医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」にてサーバーからインフラ近くまでをメインに担当しております。`}</p>
    <p>{`先日開催された`}<a parentName="p" {...{
        "href": "https://events.html5j.org/conference/2018/11/"
      }}>{`HTML5 Conference 2018`}</a>{`にメドレーがスポンサーとして協賛させていただきました。`}</p>
    <p>{`今まで Web フロントをメインとしたカンファレンスに参加する機会がなかったのですが、こういった縁があり僕も参加させていただきましたので、当日の様子をレポートさせていただきます。`}</p>
    <h1>{`HTML5 Conference とは`}</h1>
    <p>{`HTML5 Web に関する最新技術のトレンドを集め、交流する祭典として 2011 年に Chrome+HTML5 Conference として始まったカンファレンスです。`}</p>
    <p>{`以降 HTML5 Conference として開催し、今年で 7 回目の開催となります。`}</p>
    <p>{`定員枠は年々埋まるのが早くなっているそうで、今年は 1600 人の定員が 1 日で埋まったほどの人気でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181207/20181207020902.png",
        "alt": "f:id:medley_inc:20181207020902p:plain",
        "title": "f:id:medley_inc:20181207020902p:plain"
      }}></img></p>
    <p><em parentName="p">{`今年のテーマは「The Web is shifting to the next gear」`}</em></p>
    <h1>{`会場の様子`}</h1>
    <p>{`500 人が収容できる大講堂や、可動式の壁で自由に大きさを変えられる講義室など会場自体はとても整備されていました。`}</p>
    <p>{`一方で立ち見、入場規制をうけてしまうほど人が多く盛況だったことが印象的でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181207/20181207021007.png",
        "alt": "f:id:medley_inc:20181207021007p:plain",
        "title": "f:id:medley_inc:20181207021007p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181207/20181207021047.png",
        "alt": "f:id:medley_inc:20181207021047p:plain",
        "title": "f:id:medley_inc:20181207021047p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181207/20181207021114.png",
        "alt": "f:id:medley_inc:20181207021114p:plain",
        "title": "f:id:medley_inc:20181207021114p:plain"
      }}></img><em parentName="p">{`弊社リーフレットを発見！`}</em></p>
    <h1>{`セッション`}</h1>
    <p>{`各セッションでは、スピーカーの方々が Web フロントに関する最新技術の様々なトレンドの発表をしていました。`}</p>
    <p>{`参加した中からいくつか気になったセッションを紹介します。`}</p>
    <h2>{`通常セッション`}</h2>
    <h3>{`TypeScript Evolution by 倉見洋輔`}</h3>
    <p>{`弊社でも TypeScript を実運用しているプロダクトがあり、また Flow を使用している他プロダクトも TypeScript へ移行しようかという話がでています。`}</p>
    <p>{`そのため、TypeScript に関するディープな話しが聞きたいなと思いこちらのセッションに参加しました。`}</p>
    <p>{`倉見さんによる本セッションでは、TypeScript に関する概要から最新情報まで、40 分で 78 枚のスライドをまさに駆け抜けるかたちで紹介いただきました。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/2644bc630b204e1b8538ef613360a68c" title="TypeScript Evolution" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`@types のリポジトリ、リリースサイクル、TC39 などとの兼ね合いと言った基本的な TypeScript に関する情報から最新の 3.x 系に含まれる変更まで、具体的なコードと共に紹介いただけたのでわかりやすいセッションでした。`}</p>
    <p>{`特に構造的部分型は初めて聞いたキーワードだったのですが、例えなどを交えた説明がとても腹落ちしたことが印象的でした。`}</p>
    <p>{`これを機に型に関する知識を深めようと考えています。`}</p>
    <h3>{`PWA の導入で得られた成果と見えてきた課題 from 宍戸俊哉`}</h3>
    <p>{`今回のカンファレンスでも多くのセッションがパフォーマンス絡みでしたが、この分野はビジネスに直結するため、Web サービスをしている上では重要な分野です。`}</p>
    <p>{`もちろん弊社もパフォーマンスに関してもより良くしていこうと常に意識しています。`}</p>
    <p>{`そのため、今年 5 月の`}<a parentName="p" {...{
        "href": "https://hack.nikkei.com/blog/nikkei-featured-at-google-io/"
      }}>{`Google I/O でも紹介された`}</a>{`ことも記憶に新しい日経さんの事例は聞いておきたいと思い参加しました。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/0309b649c4ab42529afe5160c4bad351" title="PWA 導入の成果と課題 / nikkei-pwa-html5conf2018" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`Team Performance Day（通常業務はせず、パフォーマンス改善のみを各自行う日）といった日経のパフォーマンスに関する文化や、PageSpeed Insights や SpeedCurve など実際に使用しているツールやモニタリングの様子など、事例を交えての発表だったのでとてもイメージしやすくかったです。`}</p>
    <p>{`また、Performance Budget（パフォーマンス関連のメトリクスに対する予算）など最新の情報も事例を交えて紹介いただけたので、とてもためになりました。`}</p>
    <h2>{`その他のセッション`}</h2>
    <p>{`残念ながら見に行くことができなかったセッションも多々ありましたが、`}<a parentName="p" {...{
        "href": "https://events.html5j.org/conference/2018/11/session/"
      }}>{`Twitter の`}</a><a parentName="p" {...{
        "href": "https://events.html5j.org/conference/2018/11/session/"
      }}>{`ハッシュタグがとても良く整備・周知されている`}</a>{`ため、発表の様子やスライドなど後から他の部屋の情報を入手するのに非常に役に立ちました。`}</p>
    <p>{`また、動画もしばらくしたら HTML5 Conference 側から公開されるようですので`}<a parentName="p" {...{
        "href": "https://twitter.com/html5j"
      }}>{`公式 Twitter`}</a>{`または`}<a parentName="p" {...{
        "href": "https://www.youtube.com/user/html5j"
      }}>{`公式 YouTube アカウント`}</a>{`を見ておくと良さそうです。公開されたら参加していないセッションはもちろんのこと、参加したセッションも復習がてら見てみようと思います。`}</p>
    <h2>{`スペシャルセッション`}</h2>
    <p>{`最後に行われたスペシャルセッションでは、LT や Web 標準に関する難易度の高いクイズがありました。`}</p>
    <p>{`LT では AMP 対応の話から始まり PureScript、UNIQLO のパフォーマンス改善、CSS 組版の話しやブラウザの内部構造の話など、濃縮された Web フロントに関する情報が得られたのでとても勉強になりました。`}</p>
    <p>{`クイズでは Web 標準に関するかなり高度な（例えばある時期はこの回答が正しいが、現在だとこちらが正しいといったような）選択肢が散りばめられているひっかけ問題が出題され、その解説込みで Web の歴史の一部を覗くことのできたとても楽しい会となりました。`}</p>
    <p>{`いずれも現時点でライブ配信のアーカイブが公開されていますので、気になる方は覗いてみると面白いかもしれません。`}</p>
    <iframe src="https://www.youtube.com/embed/_GjWo2r96eM" width="655" height="368
" frameBorder="0" allowFullScreen=""></iframe>
    <h1>{`まとめ`}</h1>
    <p>{`はじめての Web フロントメインのカンファレンスへの参加でしたが、Web に関する最新技術の様々なトレンドの事例を含めた実務に近い知識を得ることのできるとても刺激的なカンファレンスでした。`}</p>
    <p>{`Web フロントに関しては「`}<a parentName="p" {...{
        "href": "/entry/2017/11/09/171047"
      }}>{`フロントエンドに再入門する`}</a>{`」という社内勉強会で基礎的なところを学んでいましたが、最近はサーバーがメインだったこともあり情報が不足しがちでした。`}</p>
    <p>{`今回のカンファレンスは、特に不足を感じていた最新情報をアップデートする良い機会となりました。`}</p>
    <p>{`また、長年運営されてるイベントで公式の`}<a parentName="p" {...{
        "href": "https://events.html5j.org/conference/2018/11/session/"
      }}>{`Twitter ハッシュタグがよく整備・周知されていたため`}</a>{`、各セッションの資料が収集しやすかったのはとても助かりました。`}</p>
    <p>{`その他にも、通常のセッションの他にクイズなど会場参加型のものもあり、開催側が参加者を飽きさせない工夫がそこかしこにあり、カンファレンス全体を通して楽しむことができました。`}</p>
    <p>{`来年もチャンスがあればまた参加したいと思います。`}</p>
    <p>{`企画された方も参加された方もお疲れ様でした！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      